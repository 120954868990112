<style lang="scss" src="./about-tabs.component.scss"></style>
<template>
  <div class="about-tabs">
    <div class="about-tabs__header mt-100">
      <h2 class="text">
        <span class="text--gray">Ut nisi ornare </span>
        <span class="text--green"> Ut maecenas adipiscing </span>
        <span class="text--gray">sed nisi ornare </span>
        <span class="text--green">ultrices id eget amet.</span>
        <span class="text--gray">.</span>
      </h2>
    </div>
    <div class="about-tabs__content">
      <tabs-component :tabs="insertTabs" @selectTab="selectInsertTab">
        <tab-component
            v-for="item in insertTabs"
            :key="item.id"
            :tab="item"
            :selected="selectInsertTabId">
          <div class="about-tabs__text">
            {{ item.description }}
          </div>
        </tab-component>
      </tabs-component>
    </div>
<!--    <div class="about-tabs__header">-->
<!--      Biz kimik-->
<!--    </div>-->
<!--    <tabs-component-->
<!--        :tabs="mainTabs"-->
<!--        @selectTab="selectTab">-->
<!--      <tab-component-->
<!--          :tab="{ name:'Biz kimik?',id:1}"-->
<!--          :selected="selectTabId">-->
<!--        <template v-for="item in abouts">-->
<!--          <div-->
<!--              :key="item.id"-->
<!--              class="about-tabs__text" v-if="item.aboutTypeId===1">-->
<!--            {{ item.description }}-->
<!--          </div>-->
<!--        </template>-->
<!--&lt;!&ndash;        <div class="about-tabs__header mt-100">&ndash;&gt;-->
<!--&lt;!&ndash;          <h2 class="text">&ndash;&gt;-->
<!--&lt;!&ndash;            <span class="text&#45;&#45;gray">Ut nisi ornare </span>&ndash;&gt;-->
<!--&lt;!&ndash;            <span class="text&#45;&#45;green"> Ut maecenas adipiscing </span>&ndash;&gt;-->
<!--&lt;!&ndash;            <span class="text&#45;&#45;gray">sed nisi ornare </span>&ndash;&gt;-->
<!--&lt;!&ndash;            <span class="text&#45;&#45;green">ultrices id eget amet.</span>&ndash;&gt;-->
<!--&lt;!&ndash;            <span class="text&#45;&#45;gray">.</span>&ndash;&gt;-->
<!--&lt;!&ndash;          </h2>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--        <div class="about-tabs__content">-->
<!--          <tabs-component :tabs="insertTabs" @selectTab="selectInsertTab">-->
<!--            <tab-component-->
<!--                v-for="item in insertTabs"-->
<!--                :key="item.id"-->
<!--                :tab="item"-->
<!--                :selected="selectInsertTabId">-->
<!--              <div class="about-tabs__text">-->
<!--                {{ item.description }}-->
<!--              </div>-->
<!--            </tab-component>-->
<!--          </tabs-component>-->
<!--        </div>-->
<!--      </tab-component>-->
<!--      <tab-component :tab="{-->
<!--                    name:'Maliyyə hesabatı',-->
<!--                    id:2-->
<!--                  }" :selected="selectTabId">-->
<!--        <template v-for="item in abouts">-->
<!--          <div-->
<!--              :key="item.id"-->
<!--              class="about-tabs__text" v-if="item.aboutTypeId===2">-->
<!--            {{ item.description }}-->
<!--          </div>-->
<!--        </template>-->
<!--        <div class="about__content mt-40">-->
<!--          <div class="row">-->
<!--            <div class="col-lg-6 col-12"-->
<!--                 v-for="item in cards"-->
<!--                 :key="item.id">-->
<!--              <card-component-->
<!--                  :information="item">-->
<!--                {{ item.description }}-->
<!--              </card-component>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </tab-component>-->
<!--    </tabs-component>-->
  </div>
</template>
<script src="./about-tabs.component.js"></script>
