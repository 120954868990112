<style lang="scss" src="./about-info.component.scss"></style>
<template>
  <div class="about-info">
    <h1 class="text">
      <span
          v-for="(item, index) in title?.split(' ')"
          :key="index"
          class="text">{{ item + ' ' }}
        </span>
    </h1>
    <div class="paragraph mt-30">
      {{description }}
    </div>
  </div>
</template>
<script src="./about-info.component.js"></script>
