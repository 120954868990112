import TabsComponent from '@/components/shared/tabs/tabs.component.vue';
import TabComponent from '@/components/shared/tab/tab.component.vue';
import CardComponent from '@/components/shared/card/card.component.vue';
import {mapGetters} from 'vuex';
// import {mapGetters} from 'vuex';

export default {
    name: 'about-tabs-component',
    components: {
        TabsComponent,
        TabComponent,
        CardComponent
    },
    props: {
        abouts: {
            type: Array
        },
        insertTabs: {
            type: Array
        },
    },
    data() {
        return {
            selectTabId: 1,
            selectInsertTabId: 0,
            mainTabs: [
                {
                    title: 'Biz kimik?',
                    id: 1
                },
                {
                    title: 'Maliyyə hesabatı',
                    id: 2
                }
            ],
        };
    },
    methods: {
        selectTab(info) {
            this.selectTabId = info;
        },
        selectInsertTab(info) {
            this.selectInsertTabId = info;
        }
    },
    computed: {
        ...mapGetters({
            local: '_getLocal'
        }),
    },
    updated() {
        this.selectInsertTabId = this.insertTabs[0]?.id;
    }
};
