<style lang="scss" src="./about.component.scss"></style>
<template>
  <div class="about mb-100">
    <div class="row">
      <div class="col-lg-6 col-12">
        <about-info-component
            :title="leadAbout.title"
            :description="leadAbout.description"/>
      </div>
      <div class="col-lg-6 col-12">
        <div class="about__image img-banner">
          <img :src="leadAbout?.fileUrl" alt="about">
        </div>
      </div>
      <div class="col-12">
        <about-tabs-component
            :insertTabs="insertTabs"
            :abouts="abouts"
        />
      </div>
    </div>
  </div>
</template>
<script src="./about.component.js"></script>
