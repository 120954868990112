import AboutInfoComponent from '@/views/about/components/about-info/about-info.component.vue';
import AboutTabsComponent from '@/views/about/components/about-tabs/about-tabs.component.vue';
import {mapGetters} from 'vuex';
import InfoComponent from "@/components/shared/info/info.component.vue";

export default {
    name: 'about-component',
    components: {
        AboutInfoComponent,
        AboutTabsComponent,
        InfoComponent
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            leadAbout: 'about/_getLeadAbout',
            abouts: 'about/_getAbout',
            insertTabs: 'about/_getWorkSkills',
            local:'_getLocal'
        }),
    },
    created() {
        this.$store.dispatch('about/getAbout');
        this.$store.dispatch('about/getWorkSkills');
    }

};
