export default {
    name: 'about-info-component',
    props: {
        title: {
            type: String,
            require: true,
        },
        description: {
            type: String,
            require: true,
        }
    },
};
